import { Attribute, CharacterSpecies, OutfitBlock, PlayerCharacter, SaveableBase, Skill, Trait, Weapon, WeaponBlock } from "../model/character";
import { v4 as guid } from 'uuid'
import { SpeciesList } from "../model/species";

export const version = 6;

export const CharacterFileType = ".fnc";

export interface Pack extends SaveableBase {
  weapons: string[];
  weaponMods: string[];
  weaponVariations: string[];
  outfits: string[];
  outfitMods: string[];
  outfitVariations: string[];
  miscItems: string[];
  utilities: string[];
  traits: string[];
  perks: string[];
}

export interface CharacterItem {
  key: string;
  name: string;
  image: string;
  species: CharacterSpecies;
  subculture: string;
  level: number;
  version: string;
}

const CreateAttribtues = (id: string, name: string): Attribute => {
  return {
    id,
    name,
    alloted: 0,
    max: 5
  }
}

const CreateSkill = (id: string, name: string, attributeId?: string): Skill => {
  return {
    id: id,
    name: name,
    toolbox: false,
    trained: false,
    workshop: false,
    attributeId: attributeId
  }
}

export const CreateDefaultCombatSkills = () => {
  return [
    CreateSkill('smallarms', "Small Arms", undefined),
      CreateSkill('heavyarms', "Heavy Arms", undefined),
      CreateSkill('tactical', "Tactical", undefined),
      CreateSkill('exotic', "Exotic", undefined),
  ]
}

const defaultCharacter: PlayerCharacter = {
    name: "",
    backstory: "",
    version: version,
    id: guid(),
    competence: 0,
    competencePerLevel: 3,
    species: {
      adjustments: [],
      name: "Kaltoran",
      description: "Kaltoran",
      id: "kaltoran",
      color: "#3c61b9",
      image: SpeciesList.kaltoran.image,
      strongHits: []
    },
    subculture: "Dark Tribe",
    inventory: {
      currentInfluence: 3,
      currentKnowledge: 3,
      currentResources: 3,
      freight: 0,
      loot: 0,
      money: 0,
      networkUnits: 0,
      researchUnits: 0,
      spareTime: 5
    },
    attributes: [
      CreateAttribtues("str", "Strength"),
      CreateAttribtues("ref", "Reflexes"),
      CreateAttribtues("mob", "Mobility"),
      CreateAttribtues("foc", "Focus"),
      CreateAttribtues("int", "Intelligence"),
      CreateAttribtues("grit", "Grit"),
    ],
    primarySkills: [
      CreateSkill('wealth', "Wealth", undefined),
      CreateSkill('persuasion', "Persuasion", "foc"),
      CreateSkill('leadership', "Leadership", "str"),
      CreateSkill('psychology', "Psychology", "int"),
      CreateSkill('culture', "Culture", "grit"),
      CreateSkill('physical', "Physical", "mob"),
      CreateSkill('resolve', "Resolve", "grit"),
      CreateSkill('awareness', "Awareness", "foc"),
      CreateSkill('stealth', "Stealth", "mob"),
      CreateSkill('mechanics', "Mechanics", "str"),
      CreateSkill('electronics', "Electronics", "foc"),
      CreateSkill('computers', "Computers", "int"),
      CreateSkill('biotech', "Bio-Tech", "grit"),
      CreateSkill('medicine', "Medicine", "ref"),
      CreateSkill('astronomy', "Astronomy", "int"),
      CreateSkill('planets', "Planets", "ref"),
    ],
    combatSkills: [
      CreateSkill('smallarms', "Small Arms", undefined),
      CreateSkill('heavyarms', "Heavy Arms", undefined),
      CreateSkill('tactical', "Tactical", undefined),
      CreateSkill('exotic', "Exotic", undefined),
    ],
    spaceSkills: [
      CreateSkill('command', "Command", undefined),
      CreateSkill('engineering', "Engineering", undefined),
      CreateSkill('operations', "Operations", undefined),
      CreateSkill('gunnery', "Gunnery", undefined),
    ],
    traits: [],
    perks: [],
    complications: [],
    equipment: [],
    outfits: [],
    utilities: [],
    weapons: [],
    tradeGoods: [],
    damages: [],
    notes: "",
    limitedUses: {},
    enduranceDamageTaken: 0,
    gritRerollSpent: 0,
    cover: "None",
  }

  const defaultWeapon: Weapon = {
    base: {
        id: "",
        name: "Base Weapon",
        slots: 2,
        acquire: 0,
        critDamage: 0,
        enduranceDamage: 0,
        hitDice: 0,
        hitBonus: 0,
        range: 0,
        resources: 0,
        knowledge: 0,
        influence: 0,
        weaponType: [],
        addFeatures: [],
        removeFeatures: [],
        strongHits: [],
        passives: [],
        skillBonusId: "",
        draw: 1,
        reload: 2,
        strongHitRange: 1,
        carried: "none",
        hands: 1,
        size: "standard",
        notes: "",
        sidearmDiscount: false,
    },
    variations: [],
    modifications: [],
    id: "",
    isAcquired: false,
    isHeld: false,
    isStashed: false,
    name: "New Weapon",
    description: "",
    munitionsSpent: 0,
    reloadsPerformed: 0,
}

export const loadCharacter = (key: string): PlayerCharacter => {
    const loadedCharacterData = localStorage.getItem(`character${key}`);

    if(loadedCharacterData === null) {
      return defaultCharacter;
    }
    
    const loadedCharacter: PlayerCharacter = JSON.parse(loadedCharacterData);
    const rawCharacter = JSON.parse(loadedCharacterData);

    if(rawCharacter.version === undefined) {
      loadedCharacter.version = version;
      loadedCharacter.id = key;
    }

    if(rawCharacter.version === "2" || rawCharacter === undefined)
    {
      const complications: Trait[] = rawCharacter.complications.map((value: string) => {
        return {
          id: guid(),
          name: value,
          description: ""
        }
      })
      loadedCharacter.complications = complications;

      const perks: Trait[] = rawCharacter.perks.map((value: string) => {
        return {
          id: guid(),
          name: value,
          description: ""
        }
      })
      loadedCharacter.perks = perks;
    }

    if(rawCharacter.version === undefined || typeof(rawCharacter.version) != "number") {
      rawCharacter.version = 2
    }

    if(rawCharacter.version < 5) {
      for(const trait of loadedCharacter.traits){
        trait.strongHits = trait.strongHits ?? [];
      }
      for(const trait of loadedCharacter.perks){
        trait.strongHits = trait.strongHits ?? [];
      }
      for(const trait of loadedCharacter.complications){
        trait.strongHits = trait.strongHits ?? [];
      }
    }

    if(typeof(loadedCharacter.name) !== "string") {
      loadedCharacter.name = "Fixed Name";
    }
    if(typeof(loadedCharacter.backstory) !== "string") {
      loadedCharacter.backstory = "Fixed Backstory";
    }
  
    return { ...defaultCharacter, ...loadedCharacter,
      weapons: loadedCharacter.weapons.map(w => {
        const convertWeaponBlock = (weaponBlock: WeaponBlock) => {
          if(typeof(weaponBlock.acquire) !== "number"){
            weaponBlock.acquire = 0;
          }
          if(weaponBlock.passives === undefined) weaponBlock.passives = [];
        };

        convertWeaponBlock(w.base);
        for(var mo of w.modifications) {
          convertWeaponBlock(mo);
        };
        for(var va of w.variations) {
          convertWeaponBlock(va);
        };
        console.dir(w);
        return { ...defaultWeapon, ...w}
      }),
      outfits: loadedCharacter.outfits.map(o => {
        const convertOutfitBlock = (outfitBlock: OutfitBlock) => {
          if(typeof(outfitBlock.acquire) !== "number"){
            outfitBlock.acquire = 0;
          }
          if(outfitBlock.passives === undefined) outfitBlock.passives = [];
        };

        convertOutfitBlock(o.base);
        for(var mo of o.modifications) {
          convertOutfitBlock(mo);
        };
        for(var va of o.variations) {
          convertOutfitBlock(va);
        };
        return o;        
      })
     };
}

export const saveCharacter = (character: PlayerCharacter) => {
  character.version = version;
  localStorage.setItem(`character${character.id}`, JSON.stringify(character));
}


export const listCharacters = (): CharacterItem[] => {
  const response: CharacterItem[] = [];

  for (let index = 0; index < localStorage.length; index++) {
    const key = localStorage.key(index);
    if(key?.startsWith("character")){
      const loadedCharacterData = localStorage.getItem(key);

      if(loadedCharacterData !== null) {
        const character = <PlayerCharacter>JSON.parse(loadedCharacterData);
        let id = character.id;
        if( id === undefined || id === "") {
          id = key.slice(9);
        }
        if(typeof(character.name) !== "string") {
          character.name = "Fixed Name";
        }
        if(typeof(character.backstory) !== "string") {
          character.backstory = "Fixed Backstory";
        }
        response.push({
          key: id,
          name: character.name,
          species: character.species ?? SpeciesList.kaltoran,
          subculture: character.subculture,
          level: character.competence / character.competencePerLevel + 1,
          image: character.species?.image ?? "",
          version: `${character.version}`
        });
      }
    }
  }

  return response;
}

export const deleteCharacter = (key: string) => {
  localStorage.removeItem(`character${key}`);
}

export const newCharacter = (): PlayerCharacter => {
  return {
    ...defaultCharacter,
    id: guid(),
  }
}

export const exportCharacter = (data: PlayerCharacter) => {
  // create file in browser
  const fileName = `${data.name}-Export`;
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: "application/json" });
  const href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + CharacterFileType;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}