import { useState } from "react";
import { exportData, libraryListExport, listData, loadLibraryData, saveLibraryData } from "../../../master/libraryData";
import { Pack } from "../../../master/data";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { Equipment, Outfit, OutfitBlock, PackData, SaveableBase, Weapon, WeaponBlock } from "../../../model/character";
import { Modal } from "../../../components/Modal";
import Section from "../../../components/Section";

import AddIcon from '@mui/icons-material/Add';
import Page from "../../../components/Page";
import { Checkbox, FormGroup, Stack } from "@mui/material";
import ButtonFlat from "../../../components/ButtonFlat";
import { TextField } from "../../../components/formFields/TextField";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";

const defaultPack: Pack = {
    id: "",
    name: "",
    miscItems: [],
    outfitMods: [],
    outfits: [],
    outfitVariations: [],
    utilities: [],
    weaponMods: [],
    weapons: [],
    weaponVariations: [],
    perks: [],
    traits: [],
}

export const GetNew = (name: string): Pack => {
    return {
        ...defaultPack,
        id: v4(),
        name: name
    }
}

interface ListModalProps {
    list: SaveableBase[];
    open: boolean;
    title: string;
    selection: string[];
    onClose: () => void;
    onChange: (id: string, state: boolean) => void;
}

const ListModal = (props: ListModalProps) => {
    return <Modal
        actions={[
            {
                label: "Close",
                onClick: props.onClose,
                size: 6,
            }
        ]}
        onClose={() => undefined} open={props.open} title={`Select ${props.title}`}> 
        {props.list.map((item) => <div key={item.id}>
            <Checkbox checked={props.selection.includes(item.id)} onChange={(e) => props.onChange(item.id, e.target.checked)} /> {item.name}
        </div>)}
    </Modal>
}

interface LibraryListProps {
    title: string;
    items: SaveableBase[];
    selection: string[];
    onChange: (selection: string[]) => void;
}

const LibraryList = (props: LibraryListProps) =>{
    const [ modal, setModal ] = useState(false);
    const filteredItems = props.items.filter(i => props.selection.includes(i.id) )

    const onChange = (id: string, checked: boolean) => {
        if(checked){
            if(!props.selection.includes(id)){
                props.onChange([
                    ...props.selection,
                    id
                ]);
            }
        }else{
            props.onChange([...props.selection.filter(s => s !== id)]);
        }
    }

    return <Section title={props.title} actions={[
        {
            action: () => setModal(true),
            label: <AddIcon></AddIcon>
        }
    ]}>
        {filteredItems.map((item) => <span>{item.name}</span>)}
        <ListModal onChange={onChange} selection={props.selection} open={modal} list={props.items} title={`Manage ${props.title}`} onClose={() => setModal(false)} />
    </Section>
}

export const PackManager = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ current, setCurrent ] = useState<Pack>(loadLibraryData<Pack>('lp', id ?? "NONE") ?? GetNew("New Pack"));

    const onChange = (name: string, selection: string[]) => {
        setCurrent((old) => {
            return {
                ...old,
                [name]: selection
            };
        })
    }

    const onChangeValue = (key: string, value: any) => {
        setCurrent((old) => {
            return {
                ...old,
                [key]: value
            }
        });
    }

    const handleSave = () => {
        navigate(`/library/pack/${current.id}`, {replace: true});
        saveLibraryData("lp", current);
    }

    const handleBack = () => {
        navigate("/library");
    }

    
    
    const weaponMods = listData("lwm");
    const weaponVars = listData("lwv");
    const weapons = listData("lw");

    const outfitMods = listData("lom");
    const outfitVars = listData("lov");
    const outfits = listData("lo");

    const miscItems = listData("lmi");
    const utilities = listData("lut");
    const tradegoods = listData("tg");

    const traits = listData("ltr");
    const perks = listData("lpe");

    

    const handleExport = () => {
        const exportWeapons = libraryListExport<Weapon>("lw", current.weapons);
        const exportWeaponMods = libraryListExport<WeaponBlock>("lwm", current.weaponMods);
        const exportWeaponVars = libraryListExport<WeaponBlock>("lwv", current.weaponVariations);

        const exportOutfits = libraryListExport<Outfit>("lo", current.outfits);
        const exportOutfitMods = libraryListExport<OutfitBlock>("lom", current.outfitMods);
        const exportOutfitVars = libraryListExport<OutfitBlock>("lov", current.outfitVariations);

        const exportUtilities = libraryListExport<Equipment>("lov", current.utilities);
        const exportMiscItems = libraryListExport<Equipment>("lov", current.miscItems);

        const exportTraits = libraryListExport<Equipment>("ltr", current.traits);
        const exportPerks = libraryListExport<Equipment>("lpe", current.perks);

        const packExport: PackData = {
            id: current.id,
            name: current.name,
            weapons: exportWeapons,
            weaponMods: exportWeaponMods,
            weaponVariations: exportWeaponVars,
            outfits: exportOutfits,
            outfitMods: exportOutfitMods,
            outfitVariation: exportOutfitVars,
            utilities: exportUtilities,
            miscItems: exportMiscItems,
            traits: [],
            perks: [],
        }
        exportData(packExport);
    }

    return <Page>
        <h2>{current.name}</h2>

        <Stack spacing={1}>
            <FormFieldLabel label="Name">
                <TextField value={current.name} onChange={(value) => onChangeValue("name", value)} />
            </FormFieldLabel>
            <LibraryList onChange={(s) => onChange("weapons", s)} items={weapons} selection={current.weapons} title="Weapons" />
            <LibraryList onChange={(s) => onChange("weaponMods", s)} items={weaponMods} selection={current.weaponMods} title="Weapon Mods" />
            <LibraryList onChange={(s) => onChange("weaponVariations", s)} items={weaponVars} selection={current.weaponVariations} title="Weapon Variations" />
 
            <LibraryList onChange={(s) => onChange("outfits", s)} items={outfits} selection={current.outfits} title="Outfits" />
            <LibraryList onChange={(s) => onChange("outfitMods", s)} items={outfitMods} selection={current.outfitMods} title="Outfit Mods" />
            <LibraryList onChange={(s) => onChange("outfitVariations", s)} items={outfitVars} selection={current.outfitVariations} title="Outfit Variations" />
 
            <LibraryList onChange={(s) => onChange("miscItems", s)} items={miscItems} selection={current.miscItems} title="Misc Items" />
            <LibraryList onChange={(s) => onChange("utilities", s)} items={utilities} selection={current.utilities} title="Utilities" />
            {/* <LibraryList items={weaponVars} selection={current.weaponVariations} title="Weapon Variations" /> */}

            <LibraryList onChange={(s) => onChange("traits", s)} items={traits} selection={current.traits} title="Traits" />
            <LibraryList onChange={(s) => onChange("perks", s)} items={perks} selection={current.perks} title="Perks" />

            <Stack direction={"row"} spacing={1} paddingTop={1}>
                <ButtonFlat onClick={handleSave}>Save</ButtonFlat>
                <ButtonFlat onClick={handleBack}>Back to Library</ButtonFlat>
                <ButtonFlat onClick={handleExport}>Export</ButtonFlat>
            </Stack>
        </Stack>
    </Page>
}