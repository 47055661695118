import { useState } from "react";
import { OutfitBlock, WeaponBlock, WeaponBlockBase } from "../../../model/character";
import { v4 } from 'uuid';

import { Box, Button, Chip, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { NumberField } from "../../../components/formFields/NumberField";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import { ModalContentSection } from "../../../components/Modal";
import SelectField from "../../../components/formFields/SelectField";

import { useNavigate, useParams } from "react-router-dom";
import { exportData, loadLibraryData, saveLibraryData } from "../../../master/libraryData";
import AbilityEditor from "../../characterPage/AbilityEditor";
import Page from "../../../components/Page";
import ButtonFlat from "../../../components/ButtonFlat";

type Block = WeaponBlock | WeaponBlockBase;

const defaultBlock: OutfitBlock = {
    id: "",
    name: "Outfit",
    slots: 0,
    armor: 0,
    armorAtZero: 0,
    defence: 0,
    endurance: 0,
    outfitType: [],
    addFeatures: [],
    removeFeatures: [],
    passives: [],
    strongHits: [],
    resources: 0,
    knowledge: 0,
    influence: 0,
    acquire: 0,
    notes: "",
    size: "standard"
}

export const GetNew = (name: string): OutfitBlock => {
    return {
        ...defaultBlock,
        id: v4(),
        name: name
    }
}

export interface OutfitEditorProps {
    prefix: string;
    pathType: string;
    defaultName: string;
}

export const OutfitBlockEditor = (props: OutfitEditorProps) => {
    const { prefix, pathType, defaultName } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const [ current, setCurrent ] = useState<OutfitBlock>(loadLibraryData<OutfitBlock>(prefix, id ?? "NONE") ?? GetNew(defaultName));
    const [ feature, setFeature ] = useState("");

    const handleSave = () => {
        navigate(`/library/${pathType}/${current.id}`, {replace: true});
        saveLibraryData(prefix, current);
    }

    const handleBack = () => {
        navigate("/library");
    }

    const handleExport = () => {
        exportData(current);
    }

    const onChange = (key: string, value: any) => {
        setCurrent((c) => ({
            ...c,
            [key]: value
        }))
    }

    const handleAddFeature = () => {
        const newFeatures = [ ...current.addFeatures, feature ];
        onChange("addFeatures", newFeatures);
        setFeature("");
    }

    const handleRemoveFeature = (index: number) => {
        const features = [ ...current.addFeatures ];
        features.splice(index, 1);
        onChange("addFeatures", features);
    }

    return <Page>
        <h2>{current.name}</h2>
        <FormGroup>
        <Stack spacing={1}>
            <FormFieldLabel label="Name">
                <TextField fullWidth type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
            </FormFieldLabel>
            <SelectField value={current.size} onChange={(value) => onChange("size", value)} options={[
                { name: "Size - Trinket (0.25)", value: "trinket" },
                { name: "Size - Small (1)", value: "small" } ,
                { name: "Size - Standard (2)", value: "standard" } ,
                { name: "Size - Large (3)", value: "large" } ,
            ]} />
            <FormFieldLabel label="Equipment Slots" justifyContent="end">
                <NumberField step={1} value={current.slots} onChange={(value) => onChange("slots", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Armor" justifyContent="end">
                <NumberField step={1} value={current.armor} onChange={(value) => onChange("armor", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Armor at 0 Endurance" justifyContent="end">
                <NumberField step={1} value={current.armorAtZero} onChange={(value) => onChange("armorAtZero", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Defence" justifyContent="end">
                <NumberField step={1} value={current.defence} onChange={(value) => onChange("defence", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Endurance" justifyContent="end">
                <NumberField step={1} value={current.endurance} onChange={(value) => onChange("endurance", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Acquire" justifyContent="end">
                <NumberField step={1} value={current.acquire} onChange={(value) => onChange("acquire", value)} customRenderer={(v) => `${v}t`} />
            </FormFieldLabel>
            <FormFieldLabel label="Influence" justifyContent="end">
                <NumberField step={1} value={current.influence} onChange={(value) => onChange("influence", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Resources" justifyContent="end">
                <NumberField step={1} value={current.resources} onChange={(value) => onChange("resources", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Knowledge" justifyContent="end">
                <NumberField step={1} value={current.knowledge} onChange={(value) => onChange("knowledge", value)}  customRenderer={(v) => `${v}kn`} />
            </FormFieldLabel>
        </Stack>
        <ModalContentSection title="Keywords">
            <Stack direction="row">
                <TextField sx={{flexGrow: 1}} type="text" variant="standard" value={feature} onChange={(event) => setFeature(event.currentTarget.value)} />
                <Button onClick={handleAddFeature}>Add Feature</Button>
            </Stack>
            <Stack flexWrap="wrap" useFlexGap direction="row" spacing={1}>
                {current.addFeatures.map((value, index) => <Chip onClick={() => handleRemoveFeature(index)} label={value} key={index} />)}
            </Stack>
        </ModalContentSection>
        <AbilityEditor title="Passive Abilities" abilities={current.passives} setAbilities={(value) => onChange("passives", value)} />
        <Box paddingLeft={1}>
            <Typography>Notes</Typography>
            <TextField value={current.notes} onChange={(event) => onChange('notes', event.currentTarget.value)} multiline fullWidth />
        </Box>
        <AbilityEditor title="Strong Hit Options" abilities={current.strongHits} setAbilities={(value) => onChange("strongHits", value)} />
            <Stack direction={"row"} spacing={1} paddingTop={1}>
                <ButtonFlat onClick={handleSave}>Save</ButtonFlat>
                <ButtonFlat onClick={handleBack}>Back to Library</ButtonFlat>
                <ButtonFlat onClick={handleExport}>Export</ButtonFlat>
            </Stack>
        </FormGroup>
    </Page>
}