import { SaveableBase } from "../model/character";

export const saveLibraryData = <T extends SaveableBase>(prefix: string, data: T) => {
  localStorage.setItem(`${prefix}-${data.id}`, JSON.stringify(data));
}

export const loadLibraryData = <T>(prefix: string, id: string): T | undefined => {
  const data = localStorage.getItem(`${prefix}-${id}`);

  if(data != null) {
    const dataLoaded = <T>JSON.parse(data);
    return dataLoaded;
  }

  return undefined;
}

export const listData = (prefix: string): SaveableBase[] => {
  const response: SaveableBase[] = [];

  for (let index = 0; index < localStorage.length; index++) {
    const key = localStorage.key(index);
    if(key?.startsWith(`${prefix}-`)){
      const saveableData = localStorage.getItem(key);

      if(saveableData !== null) {
        const saveableItem = <SaveableBase>JSON.parse(saveableData);
        response.push({
          id: saveableItem.id,
          name: saveableItem.name
        });
      }
    }
  }

  return response;
}

export const libraryListExport = <T>(prefix: string, ids: string[]) => {
  const exportData: T[] = [];
  for(var id of ids) {
      const data = loadLibraryData<T>(prefix, id);
      if(data !== undefined && data !== null)
        exportData.push(data);
  }
  return exportData;
}

export const LibraryFileType = ".fnc";

export const exportData = (data: SaveableBase) => {
  // create file in browser
  const fileName = `${data.name}-Export`;
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: "application/json" });
  const href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + LibraryFileType;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}