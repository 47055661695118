import { useState } from "react";
import { EquipmentBase, OutfitBlock, Trait, WeaponBlock, WeaponBlockBase } from "../../../model/character";
import { v4 } from 'uuid';

import { Box, Button, Chip, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { NumberField } from "../../../components/formFields/NumberField";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import { ModalContentSection } from "../../../components/Modal";
import SelectField from "../../../components/formFields/SelectField";

import { useNavigate, useParams } from "react-router-dom";
import { exportData, loadLibraryData, saveLibraryData } from "../../../master/libraryData";
import AbilityEditor from "../../characterPage/AbilityEditor";
import Page from "../../../components/Page";
import ButtonFlat from "../../../components/ButtonFlat";

const defaultTrait: Trait = {
    id: "",
    description: "",
    adjustments: [],
    strongHits: [],
    name: "New Trait",
}

export const GetNew = (name: string): Trait => {
    return {
        ...defaultTrait,
        id: v4(),
        name: name
    }
}

export interface TraitEditorProps {
    prefix: string;
    pathType: string;
    defaultName: string;
}

export const TraitEditor = (props: TraitEditorProps) => {
    const { prefix, pathType, defaultName } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const [ current, setCurrent ] = useState<Trait>(loadLibraryData<Trait>(prefix, id ?? "NONE") ?? GetNew(defaultName));

    const handleSave = () => {
        navigate(`/library/${pathType}/${current.id}`, {replace: true});
        saveLibraryData(prefix, current);
    }

    const handleBack = () => {
        navigate("/library");
    }

    const handleExport = () => {
        exportData(current);
    }

    const onChange = (key: string, value: any) => {
        setCurrent((c) => ({
            ...c,
            [key]: value
        }))
    }

    return <Page>
        <h2>{current.name}</h2>
        <FormGroup>
            <FormFieldLabel label="Name">
                <TextField type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
            </FormFieldLabel>
            <Box>
                <Typography>Description</Typography>
                <TextField value={current.description} onChange={(event) => onChange('description', event.currentTarget.value)} multiline fullWidth />
            </Box>
            <AbilityEditor abilities={current.strongHits} setAbilities={(value) => onChange('strongHits', value)} />
            <Stack direction={"row"} spacing={1} paddingTop={1}>
                <ButtonFlat onClick={handleSave}>Save</ButtonFlat>
                <ButtonFlat onClick={handleBack}>Back to Library</ButtonFlat>
                <ButtonFlat onClick={handleExport}>Export</ButtonFlat>
            </Stack>
        </FormGroup>
    </Page>
}