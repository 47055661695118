import React, { useState } from 'react';
import './App.css';


import { ThemeProvider } from '@mui/material/styles';
import { AddThemeCallback, CurrentTheme, RemoveThemeCallback } from './master/theme';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, Button, Link, Stack, ThemeOptions } from '@mui/material';
import { IInstallPromptEvent } from './master/installEvent';
import { CharacterPage } from './pages/CharacterPage';

import Logo from './images/fragged_empire2_logo.png';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './pages/home';
import ImportCharacterPage from './pages/ImportCharacter';
import { WeaponEditor } from './pages/library/manage/WeaponEditor';
import { OutfitEditor } from './pages/library/manage/OutfitEditor';
import { Library } from './pages/library';
import { WeaponBlockEditor } from './pages/library/manage/WeaponBlockEditor';
import { OutfitBlockEditor } from './pages/library/manage/OutfitBlockEditor';
import { EquipmentEditor } from './pages/library/manage/EquipmentEditor';
import { PackManager } from './pages/library/manage/Pack';
import { TraitEditor } from './pages/library/manage/TraitEditor';


const MainToolbar = () => {
  const navigate = useNavigate();
  return <AppBar position="relative">
    <Toolbar>
      <img onClick={() => navigate("/")} src={Logo} height="50px" style={{cursor: "pointer"}} />
      <Box />
    </Toolbar>
  </AppBar>
}

function App() {
  const [ theme, setTheme ] = useState<ThemeOptions>(CurrentTheme());


  React.useEffect(() => {
    AddThemeCallback(setTheme);

    return () => {
      RemoveThemeCallback(setTheme);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <MainToolbar />
        <main>
          <Routes>
            <Route path='/character/import' element={<ImportCharacterPage />} />
            <Route path='/character/:characterKey' element={<CharacterPage />} />
            <Route path='/library' element={<Library />} />
            <Route path='/library/weapon' element={<WeaponEditor />} />
            <Route path='/library/weapon/:weaponId' element={<WeaponEditor />} />
            <Route path='/library/weaponMod' element={<WeaponBlockEditor defaultName='Weapon Mod' pathType='weaponMod' prefix='lwm' />} />
            <Route path='/library/weaponMod/:id' element={<WeaponBlockEditor defaultName='Weapon Mod' pathType='weaponMod' prefix='lwm' />} />
            <Route path='/library/weaponVariation' element={<WeaponBlockEditor defaultName='Weapon Variation' pathType='weaponVariation' prefix='lwv' />} />
            <Route path='/library/weaponVariation/:id' element={<WeaponBlockEditor defaultName='Weapon Variation' pathType='weaponVariation' prefix='lwv' />} />
            <Route path='/library/outfit' element={<OutfitEditor />} />
            <Route path='/library/outfit/:outfitId' element={<OutfitEditor />} />
            <Route path='/library/outfitMod' element={<OutfitBlockEditor defaultName='Outfit Mod' pathType='outfitMod' prefix='lom' />} />
            <Route path='/library/outfitMod/:id' element={<OutfitBlockEditor defaultName='Outfit Mod' pathType='outfitMod' prefix='lom' />} />
            <Route path='/library/outfitVariation' element={<OutfitBlockEditor defaultName='Outfit Mod' pathType='outfitMod' prefix='lov' />} />
            <Route path='/library/outfitVariation/:id' element={<OutfitBlockEditor defaultName='Outfit Mod' pathType='outfitMod' prefix='lov' />} />
            <Route path='/library/miscItem' element={<EquipmentEditor defaultName='New Item' pathType='miscItem' prefix='lmi' />} />
            <Route path='/library/miscItem/:id' element={<EquipmentEditor defaultName='New Item' pathType='miscItem' prefix='lmi' />} />
            <Route path='/library/trait' element={<TraitEditor defaultName='New Trait' pathType='trait' prefix='ltr' />} />
            <Route path='/library/trait/:id' element={<TraitEditor defaultName='New Trait' pathType='trait' prefix='ltr' />} />
            <Route path='/library/perk' element={<TraitEditor defaultName='New Perk' pathType='perk' prefix='lpe' />} />
            <Route path='/library/perk/:id' element={<TraitEditor defaultName='New Perk' pathType='perk' prefix='lpe' />} />
            <Route path='/library/pack/' element={<PackManager />} />
            <Route path='/library/pack/:id' element={<PackManager />} />
            <Route index element={<HomePage />} />
          </Routes>
        </main>
      </BrowserRouter>
      <footer>
          <Box sx={{ textAlign: "center"}} marginTop={2}>
            <Stack spacing="2">
              <Typography variant='body2'>
                <Link href={"https://fraggedempire.com"}>Fragged Empire</Link> is a registered trade mark of Wade Dyer (Design Ministries). 
              </Typography>
              <Typography variant='body2'>App by Design Ministries &amp; Scale Bound Games.</Typography>
              <Typography variant="body2">Version 0.3.0</Typography>
            </Stack>
          </Box>
      </footer>
    </ThemeProvider>
  );
}

export default App;
