import { ReactNode, useEffect, useState } from "react";
import { PlayerCharacter, PlayerCharacterDisplay, Weapon, WeaponBlock, WeaponBlockBase } from "../../../model/character";
import { v4 } from 'uuid';

import { Box, Button, Chip, Divider, FormGroup, Stack, styled, TextField, Typography } from "@mui/material";
import { NumberField } from "../../../components/formFields/NumberField";
import { TabBar } from "../../../components/TabBar";
import AbilityEditor from "../AbilityEditor";
import StatLookupField from "../../../components/StatLookupField";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import { Modal, ModalAction, ModalContentSection } from "../../../components/Modal";
import SelectField from "../../../components/formFields/SelectField";
import { ButtonFlatIcon } from "../../../components/ButtonFlat";

import RemoveIcon from '@mui/icons-material/Remove';
import { CheckboxField } from "../../../components/formFields/CheckboxField";

interface WeaponListProps {
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    setCharacter: (character: PlayerCharacter) => void;
}

const WeaponBlockList = styled('div')({
    display: "flex",
    height: "33px"
})

const WeaponBlockListLabel = styled('div')({
    flexGrow: 1,
    paddingLeft: "8px",
    display: "flex",
})

const BlockListButton = styled('button')(({ theme }) => ({
    background: "unset",
    border: 0,
    outline: 0,
    cursor: "pointer",
    padding: 0,
    display: "inline",
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    
    "&:hover": {
        textDecoration: "underline"
    }
}));

const WeaponBlockEditor = (props: {
        weaponBlock: WeaponBlock | WeaponBlockBase,
        setWeaponBlock: (weaponBlock: WeaponBlock | WeaponBlockBase) => void,
        showSidearmOption?: boolean,
        character: PlayerCharacter }        
    ) => {
    const { weaponBlock, setWeaponBlock, character } = props;
    const [ feature, setFeature ] = useState("");

    const onChange = (key: string, value: any) => {
        setWeaponBlock({
            ...weaponBlock,
            [key]: value
        })
    }

    const handleAddFeature = () => {
        const newFeatures = [ ...weaponBlock.addFeatures, feature ];
        onChange("addFeatures", newFeatures);
        setFeature("");
    }

    const handleRemoveFeature = (index: number) => {
        const features = [ ...weaponBlock.addFeatures ];
        features.splice(index, 1);
        onChange("addFeatures", features);
    }

    return <>
        <Stack spacing={1}>
            <FormFieldLabel label="Name">
                <TextField fullWidth type="text" variant="standard" value={weaponBlock.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Hit Dice" justifyContent="end">
                <NumberField step={1} min={0} value={weaponBlock.hitDice} onChange={(value) => onChange("hitDice", value)} customRenderer={(val) => `${val}d6`}/>
            </FormFieldLabel>
            <FormFieldLabel label="Hit Bonus" justifyContent="end">
                <NumberField step={1} value={weaponBlock.hitBonus} onChange={(value) => onChange("hitBonus", value)} />
            </FormFieldLabel>
            {'skillBonusId' in weaponBlock &&
            <FormFieldLabel label="Skill Bonus" justifyContent="end"><StatLookupField onChange={(value) => onChange("skillBonusId", value)} value={weaponBlock.skillBonusId} stats={[
                                ...character.combatSkills,
                            ]} /></FormFieldLabel> }
            <FormFieldLabel label="Range" justifyContent="end">
                <NumberField step={1} value={weaponBlock.range} onChange={(value) => onChange("range", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Endurance Damage" justifyContent="end">
                <NumberField step={1} value={weaponBlock.enduranceDamage} onChange={(value) => onChange("enduranceDamage", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Crit Damage" justifyContent="end">
                <NumberField step={1} value={weaponBlock.critDamage} onChange={(value) => onChange("critDamage", value)} />
            </FormFieldLabel>
            <SelectField value={weaponBlock.size} onChange={(value) => onChange("size", value)} options={[
                { name: "Size - Trinket (0.25)", value: "trinket" },
                { name: "Size - Small (1)", value: "small" } ,
                { name: "Size - Standard (2)", value: "standard" } ,
                { name: "Size - Large (3)", value: "large" } ,
            ]} />
            <SelectField value={weaponBlock.carried} onChange={(value) => onChange("carried", value)} options={[
                { name: "Carried on person", value: "none" },
                { name: "Carried by companion.", value: "companion" } ,
                { name: "Stored (Spacecaft)", value: "stored" }
            ]} />
            <FormFieldLabel label="Equipment Slots" justifyContent="end">
                <NumberField step={1} value={weaponBlock.slots} onChange={(value) => onChange("slots", value)} />
            </FormFieldLabel>
            
            <FormFieldLabel label="Hands" justifyContent="end">
                <NumberField step={1} value={weaponBlock.hands} onChange={(value) => onChange("hands", value)}  />
            </FormFieldLabel>
            <FormFieldLabel label="Draw" justifyContent="end">
                <NumberField step={1} value={weaponBlock.draw} onChange={(value) => onChange("draw", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Strong Hit Range" justifyContent="end">
                <NumberField step={1} value={weaponBlock.strongHitRange} onChange={(value) => onChange("strongHitRange", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Reload" justifyContent="end">
                <NumberField step={1} value={weaponBlock.reload} onChange={(value) => onChange("reload", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Acquire" justifyContent="end">
                <NumberField step={1} value={weaponBlock.acquire} onChange={(value) => onChange("acquire", value)} customRenderer={(v) => `${v}t`} />
            </FormFieldLabel>
            <FormFieldLabel label="Influence" justifyContent="end">
                <NumberField step={1} value={weaponBlock.influence} onChange={(value) => onChange("influence", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Resources" justifyContent="end">
                <NumberField step={1} value={weaponBlock.resources} onChange={(value) => onChange("resources", value)} />
            </FormFieldLabel>
            {props.showSidearmOption && <FormFieldLabel label="Apply Sidearm Discount (-1 Resource)" justifyContent="end">
                <CheckboxField value={weaponBlock.sidearmDiscount} onChange={(value) => onChange("sidearmDiscount", value)} />
            </FormFieldLabel>}
            <FormFieldLabel label="Knowledge" justifyContent="end">
                <NumberField step={1} value={weaponBlock.knowledge} onChange={(value) => onChange("knowledge", value)} customRenderer={(v) => `${v}kn`} />
            </FormFieldLabel>
        </Stack>
        <ModalContentSection title="Keywords">
            <Stack direction="row">
                <TextField sx={{flexGrow: 1}} type="text" variant="standard" value={feature} onChange={(event) => setFeature(event.currentTarget.value)} />
                <Button onClick={handleAddFeature}>Add Feature</Button>
            </Stack>
            <Stack flexWrap="wrap" useFlexGap direction="row" spacing={1}>
                {weaponBlock.addFeatures.map((value, index) => <Chip onClick={() => handleRemoveFeature(index)} label={value} key={index} />)}
            </Stack>
        </ModalContentSection>
        <AbilityEditor title="Passive Abilities" abilities={weaponBlock.passives} setAbilities={(value) => onChange("passives", value)} />
        <Box paddingLeft={1}>
            <Typography>Notes</Typography>
            <TextField value={weaponBlock.notes} onChange={(event) => onChange('notes', event.currentTarget.value)} multiline fullWidth />
        </Box>
        <AbilityEditor title="Strong Hit Options" abilities={weaponBlock.strongHits} setAbilities={(value) => onChange("strongHits", value)} />
    </>
}

const defaultBlock: WeaponBlock = {
    id: "",
    name: "Weapon",
    slots: 0,
    acquire: 0,
    critDamage: 0,
    enduranceDamage: 0,
    hitDice: 0,
    hitBonus: 0,
    range: 0,
    resources: 0,
    knowledge: 0,
    influence: 0,
    weaponType: [],
    addFeatures: [],
    removeFeatures: [],
    strongHits: [],
    passives: [],
    draw: 0,
    reload: 0,
    strongHitRange: 0,
    carried: "none",
    hands: 1,
    size: "standard",
    notes: "",
    sidearmDiscount: false,
}

const defaultWeapon: Weapon = {
    base: {
        id: "",
        name: "Base Weapon",
        slots: 2,
        acquire: 0,
        critDamage: 0,
        enduranceDamage: 0,
        hitDice: 0,
        hitBonus: 0,
        range: 0,
        resources: 0,
        knowledge: 0,
        influence: 0,
        weaponType: [],
        addFeatures: [],
        removeFeatures: [],
        passives: [],
        strongHits: [],
        skillBonusId: "",
        draw: 1,
        reload: 2,
        strongHitRange: 1,
        carried: "none",
        size: "standard",
        hands: 1,
        notes: "",
        sidearmDiscount: false,
    },
    variations: [],
    modifications: [],
    id: "",
    isAcquired: false,
    isHeld: false,
    isStashed: false,
    name: "New Weapon",
    description: "",
    munitionsSpent: 0,
    reloadsPerformed: 0,
}

export const GetNewWeapon = (): Weapon => {
    return {
        ...defaultWeapon,
        id: v4()
    }
}

export const WeaponEditor = (props: {
    setWeapon: (value: Weapon) => void,
    onClose: () => void,
    weapon?: Weapon;
    character: PlayerCharacter,
}) => {
    const { weapon, character, setWeapon, onClose } = props;
    const [ current, setCurrent ] = useState<Weapon>(defaultWeapon);
    const [ currentVariation, setCurrentVariation ] = useState<number | undefined>(undefined);
    const [ currentModification, setCurrentModification ] = useState<number | undefined>(undefined);

    useEffect(() => {
        if(weapon !== undefined)
            setCurrent(weapon ?? defaultWeapon);
    }, [weapon]);

    const handleModalCancel = () => {
        onClose();
    }

    const handleModalSubmit = () => {
        setWeapon(current)
    }

    const handleAddVariation = () => {
        const variations = [ ...current.variations, { ...defaultBlock, name: "New Variation" } ]
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleRemoveVariation = (index: number) => {
        const variations = [ ...current.variations ];
        variations.splice(index,1);
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleChangeVariation = (index: number, variation: WeaponBlock) => {
        const variations = [ ...current.variations ];
        variations[index] = variation;
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleAddModification = () => {
        const modifications = [ ...current.modifications, { ...defaultBlock, name: "New Modification" } ]
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }

    const handleRemoveModification = (index: number) => {
        const modifications = [ ...current.modifications ];
        modifications.splice(index,1);
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }

    const handleChangeModification = (index: number, modification: WeaponBlock) => {
        const modifications = [ ...current.modifications ];
        modifications[index] = modification;
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }
    
    const onChange = (key: string, value: any) => {
        setCurrent((c) => ({
            ...c,
            [key]: value
        }))
    }

    const actionList: ModalAction[] = [];
    if(currentModification !== undefined || currentVariation !== undefined) {
        actionList.push({
            label: "Return",
            onClick: () => { setCurrentModification(undefined); setCurrentVariation(undefined); },
            size: 12
        })
    }
    actionList.push({
        label: "Cancel",    
        onClick: handleModalCancel,
    });
    actionList.push({
        label: "Save",    
        onClick: handleModalSubmit,
        primary: true,
    });

    return <Modal
        title={current.name}
        open={weapon !== undefined}
        onClose={handleModalCancel}
        actions={actionList}
        >
            <FormGroup>
                <FormFieldLabel label="Name">
                    <TextField fullWidth type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
                </FormFieldLabel>
                <ModalContentSection title="Type and Variation" onAdd={handleAddVariation} collapsable>
                    {current.variations.map((variation, index) => {
                        return <WeaponBlockList>
                            <WeaponBlockListLabel><BlockListButton onClick={() => { setCurrentVariation(index); setCurrentModification(undefined); }}>{variation.name}</BlockListButton></WeaponBlockListLabel>
                            <ButtonFlatIcon onClick={() => handleRemoveVariation(index)}><RemoveIcon /></ButtonFlatIcon>
                        </WeaponBlockList>
                    })}
                </ModalContentSection>
                <ModalContentSection title="Modifications" onAdd={handleAddModification} collapsable>
                    {current.modifications.map((modification, index) => {
                        return <WeaponBlockList>
                            <WeaponBlockListLabel><BlockListButton onClick={() => { setCurrentModification(index); setCurrentVariation(undefined); }}>{modification.name}</BlockListButton></WeaponBlockListLabel>
                            <ButtonFlatIcon onClick={() => handleRemoveModification(index)}><RemoveIcon /></ButtonFlatIcon>
                        </WeaponBlockList>
                    })}
                </ModalContentSection>
                <Divider sx={{ marginY: 2}}/>

                {currentVariation !== undefined && <WeaponBlockEditor character={character} weaponBlock={current.variations[currentVariation]} setWeaponBlock={(wb) => handleChangeVariation(currentVariation, wb)} />}
                {currentModification !== undefined && <WeaponBlockEditor character={character} weaponBlock={current.modifications[currentModification]} setWeaponBlock={(wb) => handleChangeModification(currentModification, wb)} />}
                {currentModification === undefined && currentVariation === undefined && <WeaponBlockEditor  showSidearmOption character={character} weaponBlock={current.base} setWeaponBlock={(wb) => onChange("base", wb)} />}

                <Box paddingLeft={1}>
                    <Typography>Description</Typography>
                    <TextField value={current.description} onChange={(event) => onChange('description', event.currentTarget.value)} multiline fullWidth />
                </Box>
            </FormGroup>
        </Modal>
}