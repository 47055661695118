import { useState } from "react";
import { WeaponBlock, WeaponBlockBase } from "../../../model/character";
import { v4 } from 'uuid';

import { Box, Button, Chip, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { NumberField } from "../../../components/formFields/NumberField";
import StatLookupField from "../../../components/StatLookupField";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import { ModalContentSection } from "../../../components/Modal";
import SelectField from "../../../components/formFields/SelectField";
import ButtonFlat from "../../../components/ButtonFlat";

import { CheckboxField } from "../../../components/formFields/CheckboxField";
import { useNavigate, useParams } from "react-router-dom";
import { exportData, loadLibraryData, saveLibraryData } from "../../../master/libraryData";
import AbilityEditor from "../../characterPage/AbilityEditor";
import Page from "../../../components/Page";
import { CreateDefaultCombatSkills } from "../../../master/data";

type Block = WeaponBlock | WeaponBlockBase;

const defaultBlock: WeaponBlock = {
    id: "",
    name: "Weapon ",
    slots: 0,
    acquire: 0,
    critDamage: 0,
    enduranceDamage: 0,
    hitDice: 0,
    hitBonus: 0,
    range: 0,
    resources: 0,
    knowledge: 0,
    influence: 0,
    weaponType: [],
    addFeatures: [],
    removeFeatures: [],
    strongHits: [],
    passives: [],
    draw: 0,
    reload: 0,
    strongHitRange: 0,
    carried: "none",
    hands: 1,
    size: "standard",
    notes: "",
    sidearmDiscount: false,
}

export const GetNew = (name: string): WeaponBlock => {
    return {
        ...defaultBlock,
        id: v4(),
        name: name
    }
}

export interface WeaponEditorProps {
    prefix: string;
    pathType: string;
    defaultName: string;
}

export const WeaponBlockEditor = (props: WeaponEditorProps) => {
    const { prefix, pathType, defaultName } = props;
    const { id } = useParams();
    const navigate = useNavigate();
    const [ current, setCurrent ] = useState<Block>(loadLibraryData<Block>(prefix, id ?? "NONE") ?? GetNew(defaultName));
    const [ feature, setFeature ] = useState("");

    const handleSave = () => {
        navigate(`/library/${pathType}/${current.id}`, {replace: true});
        saveLibraryData(prefix, current);
    }

    const handleBack = () => {
        navigate("/library");
    }

    const handleExport = () => {
        exportData(current);
    }

    const onChange = (key: string, value: any) => {
        setCurrent((c) => ({
            ...c,
            [key]: value
        }))
    }

    const handleAddFeature = () => {
        const newFeatures = [ ...current.addFeatures, feature ];
        onChange("addFeatures", newFeatures);
        setFeature("");
    }

    const handleRemoveFeature = (index: number) => {
        const features = [ ...current.addFeatures ];
        features.splice(index, 1);
        onChange("addFeatures", features);
    }

    return <Page>
        <h2>{current.name}</h2>
        <FormGroup>
            <Stack spacing={1}>
                <FormFieldLabel label="Name">
                    <TextField fullWidth type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Hit Dice" justifyContent="end">
                    <NumberField step={1} min={0} value={current.hitDice} onChange={(value) => onChange("hitDice", value)} customRenderer={(val) => `${val}d6`}/>
                </FormFieldLabel>
                <FormFieldLabel label="Hit Bonus" justifyContent="end">
                    <NumberField step={1} value={current.hitBonus} onChange={(value) => onChange("hitBonus", value)} />
                </FormFieldLabel>
                {'skillBonusId' in current &&
                <FormFieldLabel label="Skill Bonus" justifyContent="end"><StatLookupField onChange={(value) => onChange("skillBonusId", value)} value={current.skillBonusId} stats={[
                                    ...CreateDefaultCombatSkills(),
                                ]} /></FormFieldLabel> }
                <FormFieldLabel label="Range" justifyContent="end">
                    <NumberField step={1} value={current.range} onChange={(value) => onChange("range", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Endurance Damage" justifyContent="end">
                    <NumberField step={1} value={current.enduranceDamage} onChange={(value) => onChange("enduranceDamage", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Crit Damage" justifyContent="end">
                    <NumberField step={1} value={current.critDamage} onChange={(value) => onChange("critDamage", value)} />
                </FormFieldLabel>
                <SelectField value={current.size} onChange={(value) => onChange("size", value)} options={[
                    { name: "Size - Trinket (0.25)", value: "trinket" },
                    { name: "Size - Small (1)", value: "small" } ,
                    { name: "Size - Standard (2)", value: "standard" } ,
                    { name: "Size - Large (3)", value: "large" } ,
                ]} />
                <SelectField value={current.carried} onChange={(value) => onChange("carried", value)} options={[
                    { name: "Carried on person", value: "none" },
                    { name: "Carried by companion.", value: "companion" } ,
                    { name: "Stored (Spacecaft)", value: "stored" }
                ]} />
                <FormFieldLabel label="Equipment Slots" justifyContent="end">
                    <NumberField step={1} value={current.slots} onChange={(value) => onChange("slots", value)} />
                </FormFieldLabel>
                
                <FormFieldLabel label="Hands" justifyContent="end">
                    <NumberField step={1} value={current.hands} onChange={(value) => onChange("hands", value)}  />
                </FormFieldLabel>
                <FormFieldLabel label="Draw" justifyContent="end">
                    <NumberField step={1} value={current.draw} onChange={(value) => onChange("draw", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Strong Hit Range" justifyContent="end">
                    <NumberField step={1} value={current.strongHitRange} onChange={(value) => onChange("strongHitRange", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Reload" justifyContent="end">
                    <NumberField step={1} value={current.reload} onChange={(value) => onChange("reload", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Acquire" justifyContent="end">
                    <NumberField step={1} value={current.acquire} onChange={(value) => onChange("acquire", value)} customRenderer={(v) => `${v}t`} />
                </FormFieldLabel>
                <FormFieldLabel label="Influence" justifyContent="end">
                    <NumberField step={1} value={current.influence} onChange={(value) => onChange("influence", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Resources" justifyContent="end">
                    <NumberField step={1} value={current.resources} onChange={(value) => onChange("resources", value)} />
                </FormFieldLabel>
                {true && <FormFieldLabel label="Apply Sidearm Discount (-1 Resource)" justifyContent="end">
                    <CheckboxField value={current.sidearmDiscount} onChange={(value) => onChange("sidearmDiscount", value)} />
                </FormFieldLabel>}
                <FormFieldLabel label="Knowledge" justifyContent="end">
                    <NumberField step={1} value={current.knowledge} onChange={(value) => onChange("knowledge", value)} customRenderer={(v) => `${v}kn`} />
                </FormFieldLabel>
            </Stack>
            <ModalContentSection title="Keywords">
                <Stack direction="row">
                    <TextField sx={{flexGrow: 1}} type="text" variant="standard" value={feature} onChange={(event) => setFeature(event.currentTarget.value)} />
                    <Button onClick={handleAddFeature}>Add Feature</Button>
                </Stack>
                <Stack flexWrap="wrap" useFlexGap direction="row" spacing={1}>
                    {current.addFeatures.map((value, index) => <Chip onClick={() => handleRemoveFeature(index)} label={value} key={index} />)}
                </Stack>
            </ModalContentSection>
            <AbilityEditor title="Passive Abilities" abilities={current.passives} setAbilities={(value) => onChange("passives", value)} />
            <Box paddingLeft={1}>
                <Typography>Notes</Typography>
                <TextField value={current.notes} onChange={(event) => onChange('notes', event.currentTarget.value)} multiline fullWidth />
            </Box>
            <AbilityEditor title="Strong Hit Options" abilities={current.strongHits} setAbilities={(value) => onChange("strongHits", value)} />

                <Stack direction={"row"} spacing={1} paddingTop={1}>
                    <ButtonFlat onClick={handleSave}>Save</ButtonFlat>
                    <ButtonFlat onClick={handleBack}>Back to Library</ButtonFlat>
                    <ButtonFlat onClick={handleExport}>Export</ButtonFlat>
                </Stack>
            </FormGroup>
        </Page>
}